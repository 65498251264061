/**
 * This method is called on initial page load, formats the URL, returns an object of parameters.
 */
export const getUrlParams = (url) => {
	const urlParams = {};

	// advertising code was added to target specific vehicles
	// to achieve that we get the url params with make and  model that is computed in the cms
	// added to the widgetData props so that we can use it here.

	let urlOrAdvparams = url;

	const hasAdvertisingUrl = url.includes('tcdcmpid');

	if (hasAdvertisingUrl) {
		urlOrAdvparams =
			window.DDC.WidgetData['inventory-data-bus1'].props.params || '';
		// adding ? to the urlOrAdvparams in the beginning of the string so that it looks like querystring and help in creating object of parameters.
		urlOrAdvparams = urlOrAdvparams
			? urlOrAdvparams.replace(/^/, '?')
			: url;
	}

	urlOrAdvparams.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => {
		// DE400422: Decodes query params from a URL correctly - i.e. 'Honda+Accord' to 'Honda Accord'.
		// DE400422: Ignore + sign in facets, ie: "350+"
		const decodedQueryParams = decodeURIComponent(
			value.replace(/\+/g, ' ')
		);

		const formattedValue = [
			// Strings like "Honda%5C%5C%22" yield 'Honda\\"' when passed through decodeURIComponent -
			// We run the resultant string through a series of substitutions to correct this.
			decodedQueryParams
				// DE128702: Matches anchor tags added to end of url params, ie: "#inventory"
				.replace(/#.*/g, '')
				// US518146: Matches Veracode scan security vulnerability for XSS attacks
				.replace(/\\+/g, '')
				// US518146: Matches Veracode scan security vulnerability for XSS attacks
				// DE188414: Ignore " in package facets, ie: "w/7" Diagonal Screen"
				.replace(/"(?=[^%| ])|"$/g, '')
		];

		if (urlParams[key]) {
			urlParams[key] = [...urlParams[key], ...formattedValue];
		} else {
			urlParams[key] = formattedValue;
		}
	});
	return urlParams;
};

/**
 * This method takes a facetObj (which comes from the getQueryStringObj method) and returns formatted URL params.
 * @param facetObj
 * @returns {string}
 */
export const urlizeFacetObj = (facetObj) => {
	let queryString = '';

	/* eslint-disable no-constant-condition */
	switch (typeof facetObj) {
		case 'object':
			if (typeof facetObj) {
				Object.entries(facetObj).forEach(([name, values]) => {
					values.forEach((value) => {
						if (queryString) {
							queryString += '&';
						}
						queryString += `${encodeURIComponent(
							urlizeFacetObj(name)
						)}=${encodeURIComponent(value)}`;
					});
				});
				return `${queryString.length >= 1 ? '?' : ''}${queryString}`;
			} else {
				return '';
			}
		case 'string':
			return facetObj;
		case 'number':
			return String(facetObj);
		default:
			return '';
	}
	/* eslint-enable no-constant-condition */
};

/**
 * This method filters out URL params that might be inside appliedFilters and checks for the existence
 * of the `incentiveId` param not used as an appliedFilter.
 * It also removes the `vdpUrl` param so that the backend does not apply make and model filters.
 * @param urlParams {object}
 * @param appliedFilters {array}
 * @returns urlObject {object}
 */
export const filterNonInventoryParams = (
	urlParams = {},
	appliedFilters = []
) => {
	const knownList = appliedFilters.length
		? appliedFilters.map((item) => item.id)
		: [];
	/* eslint-disable no-param-reassign */
	return Object.keys(urlParams)
		.filter(
			(key) =>
				!knownList.includes(key) &&
				key !== 'incentiveId' &&
				key !== 'vdpUrl' &&
				!key.match(/^SB.*/)
		)
		.reduce((obj, key) => {
			obj[key] = urlParams[key];
			return obj;
		}, {});
};

/**
 * This method find and returns the legacy param key from the mapping to filter non inventory items
 * @param selectedFacet {string}
 * @returns key name {string}
 */
export const findSBParamsMappingKey = (selectedFacet = '') => {
	const legacyParamsMapping = {
		SBtype: 'compositeType',
		SByear: 'year',
		SBmake: 'make',
		SBmodel: 'model',
		SBbodystyle: 'bodyStyle',
		SBtrim: 'trim',
		SBprice: 'price',
		SBmileage: 'odometer',
		SBcityMPG: 'cityMpg',
		SBhighwayMPG: 'highwayMpg',
		SBstockNumber: 'stockNumber',
		SBlinkedAccountId: 'accountId'
	};

	return Object.keys(legacyParamsMapping).filter(
		(key) => legacyParamsMapping[key] === selectedFacet
	);
};
