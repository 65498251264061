export const addGeodistToSortByArray = (jsBody) => {
	const { inventoryParameters } = jsBody;
	// Check if geoZip is present
	if (!Object.hasOwn(inventoryParameters, 'geoZip')) {
		return JSON.stringify(jsBody);
	}

	const defaultSortByValue = inventoryParameters?.sortBy?.[0] || '';
	let updatedSortBy;

	switch (defaultSortByValue) {
		// When defaultSortByValue equals 'BLANK' we revert to legacy sort and inventoryParameters.sortBy returns an empty string
		case '':
			updatedSortBy = ['geodist asc'];
			break;
		case 'relevancyScore desc':
			updatedSortBy = [`geodist asc,${defaultSortByValue}`];
			break;
		default:
			updatedSortBy = [`${defaultSortByValue},geodist asc`];
			break;
	}

	return JSON.stringify({
		...jsBody,
		inventoryParameters: {
			...inventoryParameters,
			sortBy: updatedSortBy
		}
	});
};
