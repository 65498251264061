import { SET_WIS_FACETS, SET_WIS_INVENTORY } from '../actions/types';

export default function WIS(state = {}, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_WIS_FACETS:
				return action.payload;
			case SET_WIS_INVENTORY:
				return action.payload;
			// no default
		}
	}

	return state;
}
