export const LOG_ENDPOINT = '/api/widget/ws-inv-data/logRequest';

// This is one-way.
// We're only logging data.
export const logOnServer = (bodyObj) => {
	fetch(LOG_ENDPOINT, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(bodyObj)
	});
};
