import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { hysteriFetch, wrappers } from 'hysterics';
import logger from 'ws-scripts/modules/logger';

const requestSpellcheckWrapper = hysteriFetch(
	'ws-inv-data-spellcheck-proxy-fetch'
);

export const spellcheckProxyFetch = async (
	siteId,
	listingType,
	searchTerm,
	flags
) => {
	const requestBody = { siteId, listingType, searchTerm, flags };

	let spellcheckResponse;
	try {
		spellcheckResponse = await requestSpellcheckWrapper(
			'/api/widget/ws-inv-data/getSpellcheck',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			},
			{
				[wrappers.timeout.contextKey]: {
					timeout: flags['ws-inv-data-spellcheck-proxy-timeout']
				}
			}
		);
	} catch (err) {
		// This is not critical data for widget, so log error and return original search term
		logger.error(
			`spellcheckProxyFetch : ${err.name} : ${err.message} for account id ${siteId}`
		);
		setNewRelicCustomAttribute(
			'SRP ERROR',
			`ws-inv-data spellcheck proxy request failed for site: ${siteId} and search term: ${searchTerm}`
		);
		spellcheckResponse = searchTerm;
	}
	return spellcheckResponse;
};
