import logger from 'ws-scripts/modules/logger';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { hysteriFetch, wrappers } from 'hysterics';
import { isBrowser } from 'ws-scripts/modules/environment';
import { addGeodistToSortByArray } from './conditionallyUpdateSortBy';
import { publishEvent } from './pubsub';
import { geoLocationObj } from '../global-constants';
import {
	updateDdcDataLayerFromWIS,
	getQueryFacets,
	updateGtmDataLayerFromWIS
} from './tracking';
import { removeDeprecatedFacets } from './removeDeprecatedFacets';

const requestFacetWrapper = hysteriFetch('ws-inv-data-request-get-facets');
const requestInventoryWrapper = hysteriFetch(
	'ws-inv-data-request-get-inventory'
);

export const trackFetchDuration = (fetchType, endpoint) => {
	const numRequests = performance
		.getEntriesByType('resource')
		.filter((resource) => resource.name.includes(fetchType)).length;
	const timing = performance
		.getEntriesByType('resource')
		.filter((resource) => resource.name.includes(fetchType))[
		numRequests - 1
	];

	const message = `${fetchType} | ${endpoint} | duration: ${timing?.duration}, request: ${numRequests}`;
	logger.info(message);

	addNewRelicPageAction('WS INV DATA FETCH DURATION', {
		srpFetchType: fetchType,
		srpFetchEndpoint: endpoint,
		srpFetchCount: numRequests,
		srpFetchDuration: timing?.duration
	});
};

export const requestFacets = (
	invParams,
	sortBy,
	requestBody,
	eventType,
	eventData
) => {
	(async () => {
		try {
			publishEvent('ws-inv-data/facets-request');
			const jsBody = JSON.parse(requestBody);
			const { flags } = jsBody;
			const facetResponse = await requestFacetWrapper(
				'/api/widget/ws-inv-data/getFacets',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: requestBody
				},
				{
					[wrappers.timeout.contextKey]: {
						timeout: flags['ws-inv-data-wis-fetch-timeout']
					}
				}
			);
			if (facetResponse?.facets) {
				facetResponse.facets = removeDeprecatedFacets(
					facetResponse.facets
				);
				if (
					Object.prototype.hasOwnProperty.call(invParams, 'geoRadius')
				) {
					facetResponse.facets.unshift(geoLocationObj);
				}
				if (isBrowser && window.DDC.InvData) {
					window.DDC.InvData.facets = facetResponse;
					window.DDC.InvData.appliedFilters =
						facetResponse?.filters || [];
					publishEvent('ws-inv-data/facets');
				}
				if (isBrowser && window?.DDC?.dataLayer?.page) {
					const queryFacets = getQueryFacets(facetResponse?.filters);
					window.DDC.dataLayer.page.queryFacets = queryFacets || {};
					updateGtmDataLayerFromWIS(
						eventType,
						eventData,
						queryFacets,
						facetResponse?.totalCount,
						sortBy
					);
				}
			} else {
				// ↑ occurs when response from proxy is valid but no facets are returned
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-data facet request failed.\n${facetResponse}`
				);
				addNewRelicPageAction('WS INV DATA FACET REQUEST FAILED', {
					errorMsg: facetResponse
				});
			}

			if (flags['srp-track-fetch-resource-timing']) {
				trackFetchDuration('getFacets', 'WIS');
			}
		} catch (error) {
			setNewRelicCustomAttribute(
				'SRP ERROR',
				`ws-inv-data facet request failed.\n${error}`
			);
			addNewRelicPageAction('WS INV DATA FACET REQUEST FAILED', {
				errorMsg: error
			});
		}
	})();
};

export const requestInventory = (invParams, requestBody) => {
	(async () => {
		try {
			publishEvent('ws-inv-data/inventory-request');
			const jsBody = JSON.parse(requestBody);
			const { flags } = jsBody;
			const { inventoryFetchPromise } = window?.DDC?.InvData || {};
			const inventoryResponse = inventoryFetchPromise
				? await inventoryFetchPromise
				: await requestInventoryWrapper(
						'/api/widget/ws-inv-data/getInventory',
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: addGeodistToSortByArray(jsBody)
						},
						{
							[wrappers.timeout.contextKey]: {
								timeout: flags['ws-inv-data-wis-fetch-timeout']
							}
						}
				  );
			if (inventoryFetchPromise) {
				// Clean up preload Promise
				window.DDC.InvData.inventoryFetchPromise = null;
			}

			if (inventoryResponse?.inventory) {
				if (isBrowser && window.DDC.InvData) {
					window.DDC.InvData.inventory = inventoryResponse;
					publishEvent('ws-inv-data/inventory');
				}
				updateDdcDataLayerFromWIS(inventoryResponse, invParams?.sortBy);
			} else {
				// ↑ occurs when response from proxy is valid but no inventory is returned
				publishEvent('ws-inv-data/request-failed');
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-data inventory request failed.\n${inventoryResponse}`
				);
				addNewRelicPageAction('WS INV DATA INVENTORY REQUEST FAILED', {
					errorMsg: inventoryResponse
				});
			}

			if (flags['srp-track-fetch-resource-timing']) {
				trackFetchDuration('getInventory', 'WIS');
			}
		} catch (error) {
			publishEvent('ws-inv-data/request-failed');
			setNewRelicCustomAttribute(
				'SRP ERROR',
				`ws-inv-data inventory request failed.\n${error}`
			);
			addNewRelicPageAction('WS INV DATA INVENTORY REQUEST FAILED', {
				errorMsg: error
			});
		}
	})();
};

export const fetchWISVehicle = (requestBody) => {
	(async () => {
		try {
			const jsBody = JSON.parse(requestBody);
			const { flags } = jsBody;
			const { vehicleFetchPromise } = window?.DDC?.InvData || {};
			const WISResponse = vehicleFetchPromise
				? await vehicleFetchPromise
				: await requestInventoryWrapper(
						'/api/widget/ws-inv-data/getInventory',
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: requestBody
						},
						{
							[wrappers.timeout.contextKey]: {
								timeout: flags['ws-inv-data-wis-fetch-timeout']
							}
						}
				  );

			if (vehicleFetchPromise) {
				// Clean up preload Promise
				window.DDC.InvData.vehicleFetchPromise = null;
			}

			if (WISResponse.inventory) {
				if (isBrowser && window.DDC.InvData) {
					[window.DDC.InvData.featuredPromotion] =
						WISResponse.inventory;
				}
			} else {
				// ↑ occurs when response from proxy is valid but no inventory is returned
				publishEvent('ws-inv-data/vehicle-request-failed');
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-data vehicle request failed.\n${WISResponse}`
				);
				addNewRelicPageAction('WS INV DATA VEHICLE REQUEST FAILED', {
					errorMsg: WISResponse
				});
			}

			if (flags['srp-track-fetch-resource-timing']) {
				trackFetchDuration('getInventory', 'WIS');
			}
		} catch (error) {
			publishEvent('ws-inv-data/vehicle-request-failed');
			setNewRelicCustomAttribute(
				'SRP ERROR',
				`ws-inv-data vehicle request failed.\n${error}`
			);
			addNewRelicPageAction('WS INV DATA VEHICLE REQUEST FAILED', {
				errorMsg: error
			});
		}
	})();
};

export const getWISRequestBody = ({
	requestData,
	prefs,
	invParams = undefined,
	sortBy = '',
	start = 0,
	flags = undefined
}) => {
	const {
		siteId,
		locale,
		deviceType,
		pageId,
		windowId,
		widgetName,
		pageAlias,
		featuredPromotion: vin = undefined,
		useWIS
	} = requestData;

	const inventoryParameters = invParams || requestData.parameterMap || {};

	if (vin && !useWIS) {
		inventoryParameters.vin = vin;
	}

	if (sortBy.toString() !== '') {
		inventoryParameters.sortBy = [sortBy.toString()];
	}

	if (start.toString() !== '0') {
		inventoryParameters.start = [start.toString()];
	}

	if (
		inventoryParameters.geoZip &&
		inventoryParameters.geoZip.includes('null')
	) {
		inventoryParameters.geoZip = [''];
	}

	return JSON.stringify({
		siteId,
		locale,
		device: deviceType,
		pageAlias,
		pageId,
		windowId: useWIS ? 'inventory-data-bus1' : windowId,
		widgetName,
		inventoryParameters,
		preferences: !useWIS ? prefs : undefined, // preserve existing logic for featuredPromotion until full WIS rollout
		includePricing: true,
		...(flags && { flags })
	});
};

export const shouldUseWIS = (flags, paramUsewis) =>
	(flags?.['ws-inv-data-use-wis'] || paramUsewis === 'true') &&
	paramUsewis !== 'false';
